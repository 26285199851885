(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
jQuery(document).ready(function() {
  var $ = jQuery;

  $(document).bind('cbox_complete', function(){
    //setTimeout($.colorbox.next, 1500);
    if($("#colorbox").is(':visible')){
    	$('video').trigger('play');
    }
  });

  $('input.form-number').attr("type", "number");

  $('#page-supply-order-form input.form-number').keydown(function(e) {
    if ($(this).val().length >= 3 && 0 <= e.key && e.key <= 10) {
      return false;
    }
    return true;
  });

  $('header .navbar-toggle').click(function(e) {
    if ($('header .navbar-collapse').hasClass('in')) {
	  $('body').removeClass('noscroll navbar-collapse-in');
	} else {
	  $('body').addClass('noscroll navbar-collapse-in');
	}
  });

  $('header .navbar-toggle').click(function (e) {
    if ($(this).hasClass('collapsed')) {
      setTimeout(function(){
        $('#header-top').removeClass('collapsed');
      }, 50);
    } else {
      setTimeout(function(){
        $('#header-top').addClass('collapsed');
      }, 400);
    }
  });

  $('#header-top .search-icon').click(function(e) {
    $('#search-block-form').show();
    $('#search-block-form').append('<div class="overlay"></div>');
    $('#search-block-form .overlay').click(function(e) {
      $('#search-block-form').hide();
      $(this).remove();
    });
  });

  $('.bg-image, .bg-color').get().forEach(function(element) {
    var $bg_image = $(element);
    var $bg_content = $bg_image.find('.bg-content .panel-pane').detach();
    var $row = $bg_image.parents('.row');
    $bg_image = $bg_image.detach();
    $bg_image.find('.bg-content').remove();
    $row.wrapInner('<div class="bg-content"></div>');
    $row.wrapInner($bg_image);
    $bg_content.get().forEach(function(element) {
      $row.find('.panels-bootstrap-column').first().find('.panels-bootstrap-region').first().append(element);
    });
  });

    var header = require('./modules/header.js');
  	header.init();
});

},{"./modules/header.js":2}],2:[function(require,module,exports){
(function ($) {
  exports.init = function() {
	var header = $('header');
	var lastScrollY = 0;
	var clientY = 15;
	var ticking = false;

	function onScroll(e) {
      scrollY = $(window).scrollTop();
      requestTick();
	}


	function requestTick() {
      if (!ticking) {
		requestAnimationFrame(update);
		ticking = true;
      }
	}

	function update() {

	  //console.log(lastScrollY+" "+clientY);
	  if (clientY > lastScrollY) {
		  header.removeClass('scrolled');
	  } else {
	  	  header.addClass('scrolled');
	  }

	  lastScrollY = $(window).scrollTop();
      ticking = false;
	}

	window.addEventListener('scroll', onScroll, false);

	/*
	$('header .navbar-toggle').click(function(e) {
	  if ($('header .navbar-collapse').hasClass('in')) {
		$('body').removeClass('noscroll');
	  } else {
		$('body').addClass('noscroll');
	  }
	});
	*/
  };
})(jQuery);

},{}]},{},[1]);
